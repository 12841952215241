<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-question"
          main-icon-background-color-class="default lighten-5"
          main-icon-text-color="default--text"
          sub-heading-text="Facturas Pendientes"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].pendientes.toString()"
          :bottom-button-text='$store.state.statistics.listStatisticsInvoice[0].porcentajePendientes.toString()'
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-primary"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-sync"
          main-icon-background-color-class="warning lighten-5"
          main-icon-text-color="warning--text"
          sub-heading-text="Facturas Devueltas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].devueltos.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice[0].porcentajeDevueltos.toString()"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-success"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-search"
          main-icon-background-color-class="info lighten-5"
          main-icon-text-color="info--text"
          sub-heading-text="Facturas Recibidas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].recibidas.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice[0].porcentajeRecibidas.toString()"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-success"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-lock"
          main-icon-background-color-class="error lighten-5"
          main-icon-text-color="error--text"
          sub-heading-text="Facturas NO Autorizadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].no_autorizados.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice[0].porcentajeNoAutorizados.toString()"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-info"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-check"
          main-icon-background-color-class="success lighten-5"
          main-icon-text-color="success--text"
          sub-heading-text="Facturas Autorizadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].autorizados.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice[0].porcentajeAutorizados.toString()"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-info"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-print"
          main-icon-background-color-class="success lighten-5"
          main-icon-text-color="success--text"
          sub-heading-text="Facturas Notificadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].notificados.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice[0].porcentajeNotificados.toString()"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-info"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-upload"
          main-icon-background-color-class="info lighten-5"
          main-icon-text-color="info--text"
          sub-heading-text="Total de facturas procesadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice[0].total.toString()"
          bottom-button-text="100"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-warning"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'

  import {
    analyticOne,
  } from '@/data/analytic2'
  import { mapState } from 'vuex'
  export default {
    name: 'Statistics',
    metaInfo: {
      title: 'Statistics',
    },
    components: {
      'analytic-one-card': analyticOneCard,
    },
    data () {
      return {
        analyticOne,
      }
    },
    computed: {
      ...mapState('statistics', ['listStatisticsInvoice']),
    },
    created () {
      this.getStatistics()
    },
    methods: {

      async getStatistics () {
        await this.$store.dispatch('statistics/getStatistics')
      },
    },
  }
</script>
